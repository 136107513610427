<template>
  <v-container>
    <v-row>
      <v-col cols="4">
        <v-list dense>
          <v-list-item-group v-model="group">
            <v-list-item
              v-for="item in groups"
              :key="item.id"
              :value="item"
            >
              <v-list-item-icon>
                <v-icon>mdi-shape</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
        <v-btn fab color="primary" small class="ml-2" @click="editGroup = {}"><v-icon>mdi-plus</v-icon></v-btn>
      </v-col>

      <v-col cols="8">
        <v-card v-if="editGroup">
          <v-card-title class="d-flex justify-space-between">
            <div>{{ editGroup.id ? 'Gruppe bearbeiten' : 'Neue Gruppe erstellen' }}</div>
            <v-btn icon @click="editGroup = null; group = null"><v-icon>mdi-close</v-icon></v-btn>
          </v-card-title>
          <v-card-text>
            <v-text-field v-model="editGroup.name" label="Name"/>
            <v-textarea v-model="editGroup.desc" label="Beschreibung"/>
            <v-checkbox v-model="editGroup.public" label="für alle sichtbar?"/>
          </v-card-text>
          <v-card-actions>
            <v-spacer/>
            <v-btn v-if="editGroup.id" color="grey" text :loading="deleting" @click="del">Löschen</v-btn>
            <v-btn color="primary" text :loading="saving" @click="save">Speichern</v-btn>
            <v-spacer/>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'GroupsAdmin',
  data () {
    return {
      groups: null,
      group: null,
      editGroup: null,
      saving: false,
      deleting: false
    }
  },
  methods: {
    subscribe () {
      if (this.unsubscribe) {
        this.unsubscribe()
      }

      this.unsubscribe = this.$fb.db.collection('groups')
        .orderBy('name')
        .onSnapshot(snap => {
          const groups = []
          snap.forEach(doc => {
            const group = { id: doc.id, ...doc.data() }
            if (!group._deleted) {
              groups.push(group)
            }
          })
          this.groups = groups
        })
    },
    async save () {
      this.saving = true
      const saveGroupFunc = this.$fb.fn.httpsCallable('saveGroup')
      const response = await saveGroupFunc({ ...this.editGroup })
      if (response.data.ok) {
        this.editGroup = null
        this.group = null
      }
      this.saving = false
      this.$root.reloadData()
    },
    async del () {
      this.deleting = true
      const delGroupFunc = this.$fb.fn.httpsCallable('delGroup')
      const response = await delGroupFunc({ id: this.editGroup.id })
      if (response.data.ok) {
        this.editGroup = null
        this.group = null
      }
      this.deleting = false
      this.$root.reloadData()
    }
  },
  watch: {
    group (group) {
      this.editGroup = group ? { ...group } : null
    }
  },
  created () {
    this.subscribe()
  },
  beforeDestroy () {
    this.unsubscribe()
  }
}
</script>
